import React from 'react'
import { toast } from 'react-toastify'
import { Stack } from '@mui/system'
import { Button, Container, Typography, OutlinedInput, InputAdornment } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import { capitalize } from 'lodash'

import { FullScreenLoader } from '../../components'
import { useUpdateUserStatusMutation, useGetUsersQuery } from '../../redux/api/userApi'
import { useGetActiveUserRoleListQuery } from '../../redux/api/roleApi'
import { useGetApplicationListQuery } from '../../redux/api/applicationApi'
import { UserForm } from './UserForm'
import { UserTable } from '../../components/Table/UsersTable'
import { IUpdateStatusRequest, IUser } from '../../types'
import axios from 'axios'

export function UserManagementPage() {
  const { isLoading, isError, error, data: users, refetch } = useGetUsersQuery()

  const [updateUserStatus, { isLoading: disabling, isSuccess }] = useUpdateUserStatusMutation()
  const { data: userRoleList } = useGetActiveUserRoleListQuery()
  const { data: applicationList } = useGetApplicationListQuery(1)
  const [isVisible, setIsVisible] = React.useState(false)
  const [searchKey, setSearchKey] = React.useState<string>('')
  const [editableUser, setEditableUser] = React.useState<IUser>()
  const [dealerGroupNumberList, setDealerGroupNumberList] = React.useState<any>([])
  const getCookie = (cookieName: string): string | null => {
    const name = `${cookieName}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const cookieArray = decodedCookie.split('; ')

    for (const cookie of cookieArray) {
      if (cookie.startsWith(name)) {
        return cookie.substring(name.length)
      }
    }
    return null
  }
  React.useEffect(() => {
    const fetchDealerGroupData = async () => {
      const contractBaseUrl = `${process.env.REACT_APP_CONTRACT_SERVICE_API_ENDPOINT}/`
      const url = `${contractBaseUrl}dealerGrouplist`
      let token = getCookie('access_tokenn')

      if (!token) {
        token = localStorage.getItem('access_token')
      }

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        setDealerGroupNumberList(response.data.data)
      } catch (err: any) {
        console.log(err.message || 'Failed to fetch data')
      }
    }

    fetchDealerGroupData()
  }, [])

  React.useEffect(() => {
    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        ;(error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any).data.message, {
          position: 'top-right',
        })
      }
    }
  }, [isLoading])

  React.useEffect(() => {
    if (isSuccess) {
      refetch()
    }
  }, [disabling])

  const handleDeactivate = (data: IUpdateStatusRequest) => {
    updateUserStatus(data)
  }

  if (isLoading || !users || disabling) {
    return <FullScreenLoader />
  }

  return (
    <Container maxWidth='xl' sx={{ paddingTop: 4, paddingBottom: 8 }}>
      <Stack direction='row' justifyContent='space-between' mb={2} mr={1}>
        <Typography variant='h4' color='#1E90FF'>
          Users
        </Typography>
        <Stack direction='row' alignItems='center'>
          <OutlinedInput
            placeholder='Search'
            onChange={(event) => setSearchKey(event.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            }
            sx={{ marginRight: '20px' }}
          />
          <Button
            variant='outlined'
            sx={{ borderRadius: '20px', height: '40px' }}
            startIcon={<AddIcon />}
            onClick={() => {
              setIsVisible(true)
            }}
            size='medium'
          >
            Create
          </Button>
        </Stack>
      </Stack>
      <UserTable
        rows={users}
        searchKey={searchKey}
        disableUser={handleDeactivate}
        editUser={(user) => {
          setIsVisible(true)
          setEditableUser(user)
        }}
      />
      <UserForm
        open={isVisible}
        onClose={() => {
          setEditableUser(undefined)
          setIsVisible(false)
        }}
        refetch={refetch}
        options={userRoleList?.map((userRole) => ({
          label: capitalize(userRole.userRole),
          value: userRole.userRoleId,
        }))}
        applications={applicationList?.map((application) => ({
          label: capitalize(application.application),
          value: application.applicationId,
        }))}
        user={editableUser}
        dealerGroupNumberList={dealerGroupNumberList}
      />
    </Container>
  )
}
